import { TZDateMini, tz } from '@date-fns/tz'
import { Badge } from '@repo/ui/components/Badge.js'
import { Button } from '@repo/ui/components/Button.js'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '@repo/ui/components/Card.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@repo/ui/components/Popover.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { cn } from '@repo/ui/utils/utils'
import {
  addMonths,
  differenceInMonths,
  isPast,
  isSameMonth,
  isToday,
  startOfMonth,
  subMonths
} from 'date-fns'
import { useState } from 'react'
import { Link, href } from 'react-router'
import { VendorIcon } from '~/components/VendorIcon'
import { formatUTC } from '~/utils/dates'
import type { UpcomingDate } from './routeConfig'

export const FUTURE_MONTH_LIMIT = 12

const daysOfWeek = ['S', 'M', 'T', 'W', 'Th', 'F', 'S']

export const ContractCalendar = ({ dates }: { dates: UpcomingDate[] }) => {
  const startOfThisMonth = startOfMonth(new TZDateMini(new Date(), 'UTC'))
  const [selectedMonth, setSelectedMonth] = useState(startOfThisMonth)

  const canNavigateForward =
    differenceInMonths(selectedMonth, startOfThisMonth) < FUTURE_MONTH_LIMIT

  const canNavigateBackward =
    differenceInMonths(selectedMonth, startOfThisMonth) > 0

  const daysThisMonth = dates.filter((d) =>
    isSameMonth(new TZDateMini(d.date, 'UTC'), selectedMonth)
  )

  const startEmptyDays = daysThisMonth.at(0)?.dayOfWeek ?? 0

  const endEmptyDays =
    6 - (daysThisMonth.at(daysThisMonth.length - 1)?.dayOfWeek ?? 0)

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between gap-4">
          <CardTitle>Contract calendar</CardTitle>
          <div className="flex items-center gap-2">
            <Badge variant="secondary">
              {formatUTC(selectedMonth, 'MMMM yyyy')}
            </Badge>
            <Button
              variant="outline"
              size="xs"
              onClick={() =>
                setSelectedMonth(subMonths(selectedMonth, 1, { in: tz('UTC') }))
              }
              disabled={!canNavigateBackward}
            >
              <Icon name="chevron-left" className="size-3.5" />
            </Button>

            <Button
              variant="outline"
              size="xs"
              onClick={() =>
                setSelectedMonth(addMonths(selectedMonth, 1, { in: tz('UTC') }))
              }
              disabled={!canNavigateForward}
            >
              <Icon name="chevron-right" className="size-3.5" />
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="pt-8">
        <div className="mb-2 grid grid-cols-7 gap-1 text-sm font-medium text-muted-foreground">
          {daysOfWeek.map((day, i) => (
            <div key={i} className="w-full text-center">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-1">
          {Array.from({ length: startEmptyDays }).map((_, i) => (
            <div key={`start-empty-${i}`} className="h-full w-full" />
          ))}

          {daysThisMonth.map((day) => (
            <CalendarDay
              key={`${day.month}-${day.dayOfMonth}-${day.year}`}
              day={day}
            />
          ))}

          {Array.from({ length: endEmptyDays }).map((_, i) => (
            <div key={`end-empty-${i}`} className="h-full w-full" />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

const CalendarDay = ({ day }: { day: UpcomingDate }) => {
  const dayIsPast = isPast(new TZDateMini(day.date, 'UTC'))
  const dayIsToday = isToday(new TZDateMini(day.date, 'UTC'))

  return (
    <div
      className={cn(
        'flex min-h-16 flex-col gap-2 rounded-lg border border-border p-2',
        dayIsPast && !dayIsToday && 'opacity-30',
        dayIsToday && 'border-brand-500'
      )}
    >
      <div className="text-xs text-muted-foreground">{day.dayOfMonth}</div>
      {day.milestones.length ? (
        <div className="flex flex-wrap items-center gap-1">
          {day.milestones.map((m) => (
            <Popover key={`${m.contractId}-${m.milestoneType}`}>
              <PopoverTrigger>
                <div className="rounded-sm border border-border bg-background p-0.5">
                  <VendorIcon
                    src={m.vendor.iconUrl}
                    className="transition-all duration-300 hover:scale-110 size-5"
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent align="start" className="space-y-2 p-2">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <VendorIcon src={m.vendor.iconUrl} className="size-4" />
                    <Link
                      to={href('/stack/:contractId', {
                        contractId: m.contractId
                      })}
                      className="underline-offset-2 hover:underline text-sm font-medium"
                    >
                      {m.vendor.name}
                    </Link>
                  </div>
                  <Separator />
                  <div className="text-sm">
                    {m.milestoneType === 'termEnd'
                      ? 'Contract ends'
                      : 'Notice deadline'}{' '}
                    on {formatUTC(m.milestoneDate, 'MMM d')}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          ))}
        </div>
      ) : null}
    </div>
  )
}
