import {
  Alert,
  AlertDescription,
  AlertTitle
} from '@repo/ui/components/Alert.js'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@repo/ui/components/Card.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { Suspense } from 'react'
import { Await, Link, href } from 'react-router'
import { HorizontalBarChart } from '~/components/HorizontalBarChart'
import { PageTitle } from '~/components/Page'
import { RoleGuard } from '~/components/RoleGuard'
import { Statistic } from '~/components/Statistic'
import { VendorIcon } from '~/components/VendorIcon'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { requireAuth } from '~/services/auth.server'
import { formatCurrency } from '~/utils/numbers'
import type { Route } from './+types/route'
import { ContractCalendar } from './ContractCalendar'
import { DashboardSkeleton } from './DashboardSkeleton'
import { UpcomingRenewals } from './UpcomingRenewals'
import { getOrgStatistics } from './getOrgStatistics.server'
import { getSpendByDepartment } from './getSpendByDepartment.server'
import { getSpendByVendor } from './getSpendByVendor.server'
import { getUpcomingDates } from './getUpcomingDates.server'
import { getUpcomingRenewals } from './getUpcomingRenewals.server'

export const loader = async ({ request }: Route.LoaderArgs) => {
  const auth = await requireAuth(request)

  return {
    auth,
    data: Promise.all([
      getOrgStatistics(auth.orgId, auth),
      getSpendByDepartment(auth.orgId, auth),
      getSpendByVendor(auth.orgId, auth),
      getUpcomingDates(auth.orgId, auth),
      getUpcomingRenewals(auth.orgId, auth)
    ])
  }
}

export default function DashboardRoute({ loaderData }: Route.ComponentProps) {
  const { data, auth } = loaderData

  const org = useAuthenticatedOrg()

  return (
    <div>
      <PageTitle>{org.name}</PageTitle>
      <Spacer size="md" />

      <Suspense fallback={<DashboardSkeleton />}>
        <Await resolve={data}>
          {([
            statistics,
            spendByDepartment,
            spendByVendor,
            upcomingDates,
            upcomingRenewals
          ]) => (
            <div className="space-y-4">
              {!auth.canViewAllContracts ? (
                <Alert icon="circle-info">
                  <div>
                    <AlertTitle>Limited view</AlertTitle>
                    <AlertDescription>
                      This data is limited to contracts that you own or that are
                      in your department.
                    </AlertDescription>
                  </div>
                </Alert>
              ) : null}
              <div className="grid grid-cols-4 gap-4">
                <Statistic
                  label="Active contracts"
                  value={statistics.activeContracts}
                  icon="pen"
                />
                <Statistic
                  label="Annual spend"
                  value={statistics.annualizedSpend}
                  icon="credit-card"
                />

                <Statistic
                  label="Annual savings"
                  value={statistics.totalAnnualizedSavings}
                  icon="piggy-bank"
                />

                <Statistic
                  label="Total savings"
                  value={statistics.totalSavings}
                  icon="piggy-bank"
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2 space-y-4">
                  <RoleGuard roles={['admin', 'viewer']}>
                    {spendByDepartment ? (
                      <Card>
                        <CardHeader>
                          <CardTitle>Spend by department</CardTitle>
                          <CardDescription>
                            Annualized based on contract term
                          </CardDescription>
                        </CardHeader>
                        <CardContent>
                          <HorizontalBarChart
                            data={spendByDepartment.map((d) => ({
                              value: d.spend,
                              label: d.name,
                              id: d.id
                            }))}
                            formatValue={formatCurrency}
                            renderLabel={(department) => (
                              <Link
                                className=" hover:underline underline-offset-2"
                                to={{
                                  pathname: href('/stack'),
                                  search: `?department=${department.id}`
                                }}
                              >
                                {department.label}
                              </Link>
                            )}
                          />
                        </CardContent>
                      </Card>
                    ) : null}
                  </RoleGuard>

                  <Card>
                    <CardHeader>
                      <CardTitle>Top vendors</CardTitle>
                      <CardDescription>
                        Annualized based on contract term
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <HorizontalBarChart
                        data={spendByVendor.map((v) => ({
                          id: v.id,
                          value: v.spend,
                          label: v.name,
                          iconUrl: v.iconUrl
                        }))}
                        formatValue={formatCurrency}
                        renderLabel={(vendor) => (
                          <Link
                            className="flex items-center gap-2 hover:underline underline-offset-2"
                            to={href('/vendors/:vendorId', {
                              vendorId: vendor.id
                            })}
                          >
                            <VendorIcon
                              src={vendor.iconUrl}
                              className="size-4"
                            />
                            <div>{vendor.label}</div>
                          </Link>
                        )}
                      />
                    </CardContent>
                  </Card>

                  <ContractCalendar dates={upcomingDates} />
                </div>

                <div className="space-y-4">
                  <UpcomingRenewals renewals={upcomingRenewals} />
                </div>
              </div>
            </div>
          )}
        </Await>
      </Suspense>
    </div>
  )
}
