import { Skeleton } from '@repo/ui/components/Skeleton.js'

export const DashboardSkeleton = () => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-4">
        {Array.from({ length: 4 }).map((_, i) => (
          <Skeleton key={i} className=" h-20 w-full" />
        ))}
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 space-y-4">
          {Array.from({ length: 3 }).map((_, i) => (
            <Skeleton key={i} className="h-96 w-full" />
          ))}
        </div>
        <div>
          <Skeleton className="h-150 w-full" />
        </div>
      </div>
    </div>
  )
}
