import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '@repo/ui/components/Card.js'
import { Link, href } from 'react-router'
import { ContractMetadata } from '~/components/ContractMetadata'
import { UserBadge } from '~/components/UserBadge'
import { VendorIcon } from '~/components/VendorIcon'
import type { UpcomingRenewal } from './routeConfig'

export const UpcomingRenewals = ({
  renewals
}: { renewals: UpcomingRenewal[] }) => {
  return (
    <Card>
      <CardHeader className="border-b border-border py-4 px-4">
        <CardTitle>Upcoming renewals</CardTitle>
      </CardHeader>

      <CardContent className="p-0">
        {renewals.length ? (
          <div className="divide divide-y divide-border">
            {renewals.map((c) => (
              <div
                key={c.id}
                className="flex items-center justify-between gap-4 px-4 py-3"
              >
                <div className="flex items-center gap-2">
                  <VendorIcon src={c.vendor.iconUrl} className="size-7" />
                  <div>
                    <div className="flex items-center gap-1">
                      <Link
                        to={href('/stack/:contractId', {
                          contractId: c.id
                        })}
                        className="underline-offset-2 hover:underline text-sm font-medium block max-w-48 truncate"
                      >
                        {c.name}
                      </Link>
                    </div>
                    <div className="divide-x divide-border flex items-center">
                      {c.department ? (
                        <ContractMetadata>
                          {c.department?.name}
                        </ContractMetadata>
                      ) : null}

                      {c.owner ? (
                        <ContractMetadata>
                          <UserBadge
                            userId={c.owner.id}
                            displayName={c.owner.fullName}
                            avatarUrl={c.owner.avatarUrl}
                            size="xs"
                          />
                        </ContractMetadata>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="text-xs text-muted-foreground">{c.termEnd}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 bg-muted/50 text-muted-foreground rounded-sm">
            No renewals in the next 90 days.
          </div>
        )}
      </CardContent>
    </Card>
  )
}
